import {LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppComponent} from './app.component';
import {AppRoutingModule} from "./app-routing.module";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import localeEn from '@angular/common/locales/en';
import localeEnExtra from '@angular/common/locales/extra/en';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {StoreModule} from '@ngrx/store';
import {EntityDataModule} from '@ngrx/data';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {EffectsModule} from '@ngrx/effects';
import {entityConfig} from "./entity-metadata";
import {SharedModule} from "./shared/shared.module";
import {MatDateFnsModule} from "@angular/material-date-fns-adapter";
import {MAT_DATE_LOCALE} from "@angular/material/core";
import {de} from "date-fns/locale";
import {DateAdapter} from "@app/shared/date-adapters/date-adapter";
import {adapterFactory} from "@app/shared/date-adapters/date-fns";
import {HttpClient} from "@angular/common/http";
import {TranslateHttpLoader} from "@codeandweb/http-loader";
import {TranslateLoader, TranslateModule} from "@codeandweb/ngx-translate";

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}

registerLocaleData(localeDe, 'de', localeDeExtra);
registerLocaleData(localeEn, 'en', localeEnExtra);

export const TRANSLATE_MODULE_CONFIG = {
  defaultLanguage: 'en',
  loader: {
    provide: TranslateLoader,
    useFactory: HttpLoaderFactory,
    deps: [HttpClient]
  }
};

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    StoreModule.forRoot({}, {}),
    EffectsModule.forRoot([]),
    EntityDataModule.forRoot(entityConfig),
    StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}),
    MatDateFnsModule,
    SharedModule,
    TranslateModule.forRoot(TRANSLATE_MODULE_CONFIG),
  ],
  providers: [
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'fill'}},
    {provide: LOCALE_ID, useValue: "de-DE"}, // locale for Angular pipes, independent of ngx-translate
    {provide: MAT_DATE_LOCALE, useValue: de},
    {provide: DateAdapter, useFactory: adapterFactory}
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
