import {EntityDataModuleConfig, EntityMetadataMap} from '@ngrx/data';

const entityMetadata: EntityMetadataMap = {};

const pluralNames = {
  Employees: 'employees',
  Customers: 'customers',
  Appointments: 'appointments',
  AppointmentTemplates: 'appointmenttemplates',
  AppointmentTemplateCategories: 'appointmenttemplatecategories',
  Files: 'files',
  PublicHolidays: 'publicholidays',
  Promotions: 'promotions',
  BookableTimeslots: 'bookable-timeslots',
  Shifts: 'shifts', // API endpoint path
  CustomDataFields: 'customdatafields'
};

export const entityConfig: EntityDataModuleConfig = {
  entityMetadata,
  pluralNames
};
