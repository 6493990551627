import {Injectable} from '@angular/core';
import {Route, Router, UrlSegment} from '@angular/router';
import {Observable, tap} from 'rxjs';
import {select, Store} from "@ngrx/store";
import {isLoggedInSelector} from "../store/selectors";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard {

  constructor(
    private router: Router,
    private store: Store
  ) {
  }

  canLoad(route: Route, segments: UrlSegment[]): Observable<boolean> {
    const returnUrl = segments.map(it => it.path).join('/');
    return this.store.pipe(
      select(isLoggedInSelector),
      tap(isLoggedIn => {
        if (!isLoggedIn)
          this.router.navigate(['/public/login'], {queryParams: {returnUrl}}).then(() => {
            console.debug('Redirected to LoginComponent');
          });
      })
    );
  }

}
