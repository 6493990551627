import {Component, Inject} from '@angular/core';
import {NavigationService} from './shared/services/navigation.service';
import {ThemeService} from './shared/services/theme.service';
import {DOCUMENT} from "@angular/common";
import {TranslateService} from "@codeandweb/ngx-translate";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Calendall';

  constructor(
    private navigationService: NavigationService,
    private themeService: ThemeService,
    private translate: TranslateService,
    @Inject(DOCUMENT) private document: Document
  ) {
    console.debug(
      'Navigation service started to record navigation events',
      navigationService
    );
    console.log('TranslateService instance initialized');

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    console.log('Browser language:', browserLang);
    const language = browserLang?.match(/en|de/) ? browserLang : 'en';
    this.document.documentElement.lang = language;

    if (this.translate.currentLang !== language)
      this.translate.use(language).subscribe(() => {
        console.log(`Loaded translations for: ${browserLang}`, this.translate.translations);
      }, err => {
        console.error(`Error loading translations for: ${browserLang}`, err);
      });
  }

  ngOnInit() {
    document.body.classList.add(this.themeService.getTheme() + '-theme');
    document.documentElement.setAttribute(
      'data-bs-theme',
      this.themeService.getTheme()
    );
  }
}
